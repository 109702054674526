import React from 'react';
import {Helmet} from 'react-helmet';

const AuthorRoles = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Author Roles | Open Access Medical Journal | Cureus</title>
        <meta name="description" content="Navigate the author roles at Cureus. From submitting authors to senior authors, know your responsibilities for a successful publication. Submit research today!" />
      </Helmet>
      <h1>Author Roles, etc.</h1>

      <p>Authors cannot be added after submission or publication. In-progress article submissions can be withdrawn at the request of the submitting author in order to resubmit with additional authors occupying different roles, such as senior authors, contributing authors, etc.</p>
      <p>However, the purchase of <a href='/cureus_editing_services'>Preferred Editing</a> will be required upon resubmission. If Preferred Editing was purchased for the initial submission, no refund will be provided and an additional purchase will be required upon resubmission.</p>
      
      <h2 className='submitting-author'>Submitting Author</h2>
      <p>The submitting author assumes all responsibility throughout the submission, <a href='/reviewer_guide'>peer review</a>, and <a href='/about_publishing'>publication process</a>. Only those in submitting author roles can edit articles, author names, contributions, and affiliations. </p>
      <p>If you are submitting on behalf of an author you must <a href='/users/sign_in'>sign in</a> with the author’s account before continuing. All Cureus email notifications sent during the submission process will be sent to the submitting author. For these reasons, we strongly recommend that authors submit their own work.</p>
      
      <h2 className='corresponding-author'>Corresponding Author</h2>
      <p>The submitting author may designate a co-author to serve as corresponding author after the article is published. Please note that the corresponding author does not have edit access.</p>
      
      <h2 className='shared-first-author'>Shared First Authorship</h2>
      <p>Cureus does not support co-first authors. If you wish to recognize two individuals in first-author roles, please do so by adding a statement to the Acknowledgments section.</p>

      <h2>All Submissions Must Include a Senior Author</h2>
      <p>Cureus considers authors with the following degrees to be senior authors: MD or DO (completed residency training), PhD, NP, PA, RN, DDS/DMD. </p>
      <p>Submissions authored by <a href='/medical_education'>medical</a> students must contain an MD or DO senior author. Similarly, submissions authored by <a href='/dentistry'>dental</a> students must contain a DDS/DMD in a senior author role. Regional equivalent degrees will also be considered.</p>

      <h2>AI Chatbots, also Known as Large Language Models(LLM)</h2>
      <p>No LLM tool, such as OpenAI's ChatGPT, will be accepted as a credited author on an article. That is because any attribution of authorship carries with it accountability for the work, and AI tools cannot take such responsibility and act as contributing authors. </p>
      <p>Authors using LLM tools should document this use in the Materials and Methods or Acknowledgements sections. <a href='/articles'>Articles</a> written entirely by an LLM tool will not be accepted.</p>
    </React.Fragment>
  )
}

export default AuthorRoles;