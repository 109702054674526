import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import SearchResults from './SearchResults';

import Introduction from './GuestEditorPages/Introduction';

import GettingStarted from './GuestEditorPages/GettingStarted';
import StepByStepInstructions from './GuestEditorPages/StepByStepInstructions';
import FrequentlyAskedQuestions from './GuestEditorPages/FrequentlyAskedQuestions';

const GuestEditorGuide = ({ query, urls, setFooterText, setIncludeDefault }) => {

  return (
    <Switch>
      <Route path="/overview/introduction">
        <Introduction setFooterText={setFooterText} setIncludeDefault={setIncludeDefault}/>
      </Route>

      <Route path="/editor-instructions/getting-started">
        <GettingStarted setFooterText={setFooterText} setIncludeDefault={setIncludeDefault}/>
      </Route>
      <Route path="/editor-instructions/step-by-step-instructions">
        <StepByStepInstructions />
      </Route>
      <Route path="/editor-instructions/frequently-asked-questions">
        <FrequentlyAskedQuestions />
      </Route>

      <Route path="/search">
        <SearchResults query={ query } guide={ 'guesteditor_guide' }/>
      </Route>
      <Redirect to="/overview/introduction"/>
    </Switch>
  )
}

export default GuestEditorGuide;