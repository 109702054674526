import React, { useState, useEffect } from 'react';
import AnswerBarRow from './AnswerBarRow';
import { Cureus } from '../../Cureus';

const AnswerOutcome = ({handleAnswer, answers, answersTotal, correctAnswerId, userAnsweredId, pictureQuizId, showAnswer, setShowAnswer, fireAmplitude, fetchPictureQuiz}) => {

  const percent = (count, answersTotal) => {
    const answerPercentage = Math.floor((count / answersTotal) * 100);
    return isNaN(answerPercentage) ? '0%' : `${answerPercentage}%`;
  }

  const renderImageText = () => {
    if(correctAnswerId == userAnsweredId) {
      return (
        <div className="large-6 medium-12 small-12 columns">
          <div className='text-emoji-container'>
            <h3 className='green-h3-text'>Correct</h3>
            <h3 className='h3-margin-bottom'>You nailed it. Great job!</h3>
            <img src='https://public.cureus.com/homepage_new/picture_prognosis_correct.png'/>
          </div>
        </div>
      )
    } else if(!userAnsweredId) {
      return (
        <div className="large-6 medium-12 small-12 columns">
          <div className='text-emoji-container'>
            <h3 className='black-h3-text'>Do you know the answer?</h3>
            <h3 className='h3-margin-bottom'>Make your choice and let's find out!</h3>
            <img src='https://public.cureus.com/homepage_new/picture_prognosis_unanswered.png'/>
          </div>
        </div>
      )
    } else {
      return (
        <div className="large-6 medium-12 small-12 columns">
          <div className='text-emoji-container'>
            <h3 className='red-h3-text'>Incorrect</h3>
            <h3 className='h3-margin-bottom'>Unfortunately, you did not</h3>
            <h3 className='h3-margin-bottom'>select the right answer.</h3>
            <img src='https://public.cureus.com/homepage_new/picture_prognosis_incorrect.png'/>
          </div>
        </div>
      )
    }
  }

  const deleteCookie = (name) => {
    document.cookie = `${name}=; Max-Age=0; path=/;`;
  }

  const resetAnswer = () => {
    deleteCookie(`picture-quiz-${pictureQuizId}`);
    window.location.href = `/picture_quizzes/${pictureQuizId}`;
    fireAmplitude("picturePrognosisTryAgain", {picturePrognosisId: pictureQuizId, source: "Show Page"});
  }

  const handleNextChallenge = () => {
    $.ajax({
      type: 'GET',
      dataType: 'json',
      url: `/picture_quizzes/${pictureQuizId}/next_challenge`,
      success: function(res) {
        window.location.href = `/picture_quizzes/${res.next_quiz_id}`;
      },
      error: function(res) {
        Cureus.Flash.alert("There is no quiz available.");
        $("html, body").animate({scrollTop: 0}, 1000);
      }
    })
  }

  const handleShowAnswer = () => {
    fireAmplitude("picturePrognosisViewAnswer", {picturePrognosisId: pictureQuizId, source: "Show Page"});
    setShowAnswer(true);
  }

  const renderButtons = () => {
    const notAnswered = !userAnsweredId
    const isCorrect = userAnsweredId === correctAnswerId
  
    if (showAnswer) {
      return (
        <>
          <a className="button next-challenge-btn correct-state" onClick={handleNextChallenge}>Next Challenge</a>
          {!isCorrect && <a className="button try-again-btn" onClick={resetAnswer}>Try Again</a>}
        </>
      )
    } else if (!isCorrect) {
      return (
        <>
          <a className="button view-answer-btn" onClick={handleShowAnswer}>View Answer</a>
          {notAnswered ? (
            <a className="button next-challenge-btn" onClick={handleNextChallenge}>Next Challenge</a>
          ) : (
            <a className="button try-again-btn" onClick={resetAnswer}>Try Again</a>
          )}
        </>
      )
    } else {
      return (
        <div className='next-challenge-container'>
          <a className="button next-challenge-btn correct-state" onClick={handleNextChallenge}>Next Challenge</a>
        </div>
      )
    }
  }

  return (
    <div className='results-container small-12 columns'>
      {renderImageText()}
      <div className="large-6 medium-12 small-12 columns">
        <div className='percent-bars-container'>
          {answers.map((answer, idx) => {
            return (
              <AnswerBarRow 
                key={idx}
                answer={answer}
                percent={percent(answer.count, answersTotal)}
                percentNumber={percent(answer.count, answersTotal)}
                correctAnswerId={correctAnswerId}
                userAnsweredId={userAnsweredId}
                handleAnswer={handleAnswer}
                showAnswer={showAnswer}
              />
            )
          })}
        </div>
        <div className="quiz-action-buttons">
          {renderButtons()}
        </div>
      </div>
    </div>
  );
}

export default AnswerOutcome;