import React from 'react';

const UpdateKlaviyoTemplateButton = ({path}) => {
  let param = $("[name='csrf-param']").attr("content");
  let csrfToken = $("[name='csrf-token']").attr("content");
  return (
    <form action={path} method="POST" >
      <input type="hidden" name={ param } value={ csrfToken } />
      <button type="submit" className="button primary">UPDATE HUBSPOT TEMPLATE AND CREATE CAMPAIGN</button>
    </form>
  );
};

export default UpdateKlaviyoTemplateButton;


