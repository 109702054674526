import React, { useState, useEffect } from 'react';
import AnswerOutcome from './AnswerOutcome';
import Clarification from './Clarification';

const Landing = ({pictureQuizId, currentUserId}) => {

  const [answers, setAnswers] = useState([]);
  const [userAnsweredId, setuserAnsweredId] = useState(null);
  const [pictureQuiz, setPictureQuiz] = useState(null);
  const [answersTotal, setAnswersTotal] = useState(0);
  const [correctAnswerId, setCorrectAnswerId] = useState(null);
  const [showAnswer, setShowAnswer] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const showAnswerUrlParam = queryParams.get('showAnswer');
    if (showAnswerUrlParam) {
      setShowAnswer(true);
    }

    fetchPictureQuiz(pictureQuizId);
    const cookie = getCookie(`picture-quiz-${pictureQuizId}`);
    setuserAnsweredId(cookie);
  }, [])

  const fetchPictureQuiz = (pictureQuizId) => {
    $.ajax({
      type: 'GET',
      dataType: 'json',
      url: `/picture_quizzes/${pictureQuizId}`,
      success: function(data) {
        const { answers, picture_quiz: pictureQuiz, total: totalAnswersCount } = data;
        setPictureQuiz(pictureQuiz);
        setAnswers(answers);
        setAnswersTotal(totalAnswersCount);
        setCorrectAnswerId(pictureQuiz.correctAnswerId)
      }
    });
  }

  const handleAnswer = (answerId) => {
    $.ajax({
      type: 'PUT',
      url: `/picture_quizzes/${pictureQuizId}`,
      data: {quiz_option_id: answerId, answered_already: false},
      success: function(data) {
        setCookie(`picture-quiz-${pictureQuizId}`, answerId, 7);
        setuserAnsweredId(answerId)
        fireAmplitude("picturePrognosisSubmit", {picturePrognosisId: pictureQuizId, answer: data.answer, correct: data.correct, source: "Show Page"});
        fetchPictureQuiz(pictureQuizId);
      }
    });
  }

  const fireAmplitude = (event, data=null) => {
    $.ajax("/amplitudes", {
      dataType: "json",
      type: "POST",
      data: { 
              event_type: event,
              user_id: currentUserId,
              ...data,
            }
    })
  }

  const renderClarification = () => {
    if (userAnsweredId || showAnswer) {
      return (
        <>
          <hr id='second-hr'/>
          <Clarification clarification={pictureQuiz.clarification} articleId={pictureQuiz.article_id}/>
        </>
      )
    }
  }

  if(!pictureQuiz) {
    return <div/>
  }
  
  return (
    <div className='small-12 columns picture-prognosis-answer-outcome-container'>
      <hr/>
      <AnswerOutcome 
        correctAnswerId={correctAnswerId}
        userAnsweredId={userAnsweredId} 
        answers={answers} 
        answersTotal={answersTotal}
        handleAnswer={handleAnswer}
        showAnswer={showAnswer}
        setShowAnswer={setShowAnswer}
        pictureQuizId={pictureQuizId}
        fetchPictureQuiz={fetchPictureQuiz}
        fireAmplitude={fireAmplitude}
      />

      {renderClarification()}
      <hr/>
    </div>
  )
}

export default Landing;