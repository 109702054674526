import React, { useState, useEffect } from 'react';
import AnswerOption from './AnswerOption';

const Widget = ({currentUserId, pictureQuizId}) => {

  const [answers, setAnswers] = useState([]);
  const [image, setImage] = useState('');
  const [question, setQuestion] = useState('');
  const [answeredForTheDay, setAnsweredForTheDay] = useState(getCookie(`picture-quiz-${pictureQuizId}`).length > 0);
  const [selectedId, setSelectedId] = useState(null);

  useEffect(() => {
    fetchPictureQuiz();
    const cookie = getCookie(`picture-quiz-${pictureQuizId}`);
    if (cookie.length > 0) {
      setSelectedId(cookie);
    }
  }, [])

  const fetchPictureQuiz = () => {
    $.ajax({
      type: 'GET',
      dataType: 'json',
      url: `/picture_quizzes/${pictureQuizId}`,
      success: function(data) {
        const { answers, picture_quiz: pictureQuiz } = data;
        setAnswers(answers);
        setImage(pictureQuiz.image.url);
        setQuestion(pictureQuiz.question);
      }
    });
  }

  const handleSubmit = () => {
    $.ajax({
      type: 'PUT',
      url: `/picture_quizzes/${pictureQuizId}`,
      data: {quiz_option_id: selectedId, answered_already: answeredForTheDay},
      success: function(data) {
        fireAmplitude('picturePrognosisSubmit', {picturePrognosisId: pictureQuizId, answer: data.answer, correct: data.correct, source: "Homepage Widget"})
        setCookie(`picture-quiz-${pictureQuizId}`, data.userAnsweredId, 7);
        window.location.href = `/picture_quizzes/${pictureQuizId}`;
      }
    });
  }

  const fireAmplitude = (event, data={}) => {
    $.ajax("/amplitudes", {
      dataType: "json",
      type: "POST",
      data: { 
              event_type: event,
              user_id: currentUserId,
              ...data,
            }
    })
  }

  const handleViewAnswer = () => {
    fireAmplitude("picturePrognosisViewAnswer", {picturePrognosisId: pictureQuizId, source: "Homepage Widget" })
    window.location.href = `/picture_quizzes/${pictureQuizId}?showAnswer=true`;
  }

  const handleViewAll = () => {
    fireAmplitude("picturePrognosisViewAll", {picturePrognosisId: pictureQuizId, source: "Homepage Widget" })
    window.location.href = "/picture_quizzes";
  }

  return (
    <div className="picture-prognsis-widget-container">
      <h3>Picture Prognosis</h3>
      {image && <img className='img-container' src={image} alt="Picture Quiz Image" />}
      <div className='question-container'>
        <p className='question-text'>{question}</p>
      </div>
      {answers.map((option, idx) => {
        return (
          <AnswerOption 
            key={idx}
            idx={idx}
            quizOption={option}
            setSelectedId={setSelectedId}
            selected={option.id == selectedId}
            fireAmplitude={fireAmplitude}
            answeredForTheDay={answeredForTheDay}
          />
        )
      })}
      <a 
        className={'button ' + (selectedId ? 'round special' : 'disabled')} 
        onClick={handleSubmit}
        style={{ pointerEvents: answeredForTheDay ? 'none' : 'auto', opacity: answeredForTheDay ? 0.5 : 1 }}
      >
        Submit
      </a>
      <div className='secondary-buttons-container'>
        <a className='button view-answer-btn' onClick={handleViewAnswer}>
          View Answer
        </a>
        <a className='button view-all-btn' onClick={handleViewAll}>
          MORE QUIZZES
        </a>
      </div>
    </div>
  )
}

export default Widget;