var createReactClass = require('create-react-class');
import React from 'react';

const StepOne = createReactClass({
  handleDisable: function() {
    return (this.props.summary.length >= 200 && this.props.assessment.length) >= 200 ? '' : 'disabled'
  },

  renderSummaryErrors: function() {
    if(this.props.summary.length < 200) {
      return (
        <div className="char-limit">A minimum of 200 characters is required to proceed.</div>
      )
    } else {
      return <div className="char-limit"/>
    }
  },

  renderAssessmentErrors: function() {
    if(this.props.assessment.length < 200) {
      return (
        <div className="char-limit">A minimum of 200 characters is required to proceed.</div>
      )
    } else {
      return <div className="char-limit"/>
    }
  },
  
  render: function() {
    return (
      <div className='modal-content'>
        <h4>Peer Review Questionnaire</h4>
        <p>Almost finished! Please provide thoughtful, detailed responses with critical feedback. Providing 'Yes' or 'No' answers to the following questions without also including supporting evidence or an explanation of your rationale may result in the rejection of your peer review. Please suggest improvements when you deem it appropriate to do so. Your responses will be visible to the authors and editors.</p>
        <p><strong>Step 1: Please provide your thoughts to the author and editors.</strong></p>
        <p>Provide a brief summary of the main research question, claims, and conclusions of the study. How does this research fit within the existing literature?</p>
        <textarea name="summary" onChange={this.props.handleChange} value={this.props.summary}/>
        {this.renderSummaryErrors()}
        <p>Provide a brief assessment of the research process and presentation while noting any obvious flaws or areas of weakness. Are the conclusions consistent with the evidence presented?</p>
        <textarea name="assessment" onChange={this.props.handleChange} value={this.props.assessment}/>
        {this.renderAssessmentErrors()}
        <div className='modal-footer'>
          <a href='#' className='button secondary cancel-modal'>Cancel</a>
          <a href='#' className={'button primary ' + this.handleDisable()} onClick={this.props.handleNext}>Next</a>
        </div>
      </div>
    )
  }
})

export default StepOne;