import React, {useState, useEffect} from 'react';

const PictureQuizCard = ({pictureQuiz, showPageCard=false}) => {

  const renderCardStyle = () => {
    if (showPageCard) {
      return 'show-page-card';
    }
  }

  const redirectToShowPage = () => {
    window.location.href = `/picture_quizzes/${pictureQuiz.id}`;
  }

  return (
    <div className={'large-12 medium-12 small-12 columns picture-quiz-card ' + renderCardStyle()}>
      <div className="picture-quiz-texts">
        <p className="date-text show-for-medium-up">{pictureQuiz.published_at}</p>
        <p className="question-text" onClick={redirectToShowPage}>{pictureQuiz.question}</p>
        <div className='categories-container'>
          {pictureQuiz.categories.map((cat, idx) => {
            return (
              <a className="category" key={`cat-${idx}`}>{cat.name}</a>
            )
          })}
        </div>
      </div>
      <div className={"picture-quiz-img-container " + renderCardStyle()}>
        <p className="date-text show-for-small-only">{pictureQuiz.published_at}</p>
        <img src={pictureQuiz.image.url} onClick={redirectToShowPage}/>
      </div>
      <div className={'answer-quiz-container show-for-medium-up ' + renderCardStyle()} onClick={redirectToShowPage}>
        <a className='answer-btn'>Take the Quiz</a>
        <i className='fa fa-arrow-right' />
      </div>
    </div>
  )
}

export default PictureQuizCard;