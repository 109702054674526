import React, {useState, useEffect} from 'react';
import SpecialtyCard from './SpecialtyCard';
import Filter from '../Shared/FilterTab/Filter';
import SearchBar from '../Shared/SearchBar/SearchBar';
import { Helmet } from 'react-helmet';

const Main = () => {

  const [specialtiesForCards, setSpecialtiesForCards] = useState([]);
  const [specialtiesForFilterTab, setSpecialtiesForFilterTab] = useState([]);
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);

  useEffect(() => {
    fetchSpecialties();
  }, [])

  const fetchSpecialties = () => {
    $.ajax({
      type: "GET",
      url: `/specialties`,
      dataType: "json",
      success: function(data) {
        setSpecialtiesForCards(data.cards);
        setSpecialtiesForFilterTab(data.cards);
      }
    })
  }

  const renderSpecialtiesCards = () => {
    return (
      <div className={"large-12 medium-12 small-12 columns specialty-cards-section"}>
        {specialtiesForCards.map((specialty) => {
          return <SpecialtyCard specialty={specialty} />
        })}
      </div>
    )
  };

  return (
    <div className='row specialties-page'>
      <Helmet>
        <title> Medical Specialties | Open Access Medical Journal | Cureus</title>
        <meta name="description" content="Explore diverse medical specialties on Cureus, a platform dedicated to innovative research. Make your impact—submit your articles and connect with peers!" />
      </Helmet>
      <div className='large-11 medium-11 small-12 columns small-centered specialties-section'>
        <div className='header-container'>
          <p className='text-center mini-header'>MEDICAL SPECIALTIES</p>
          <h1 className='text-center'>Browse Medical Specialties</h1>
          <p className='sub-title'>
            Our <a href='/'>Open Access medical journal</a> supports the publication and dissemination of research across various medical disciplines. Whether you’re looking to read the latest articles or publish your own research, explore Cureus’ list of medical specialties to connect and engage with the broader medical community.
          </p>
        </div>
        <SearchBar
          url={"/specialties"}
          placeHolder={"Search for a specific specialty here."}
          specialtiesPage={true}
          setCards={setSpecialtiesForCards}
          isFilterMenuOpen={isFilterMenuOpen}
          setIsFilterMenuOpen={setIsFilterMenuOpen}
          page={"specialties"}
          isFilterRequired={true}
        />
        {renderSpecialtiesCards()}
        <div className='why-cureus-container large-12 medium-12 small-12 columns'>
          <h2>Why Cureus?</h2>
          <div className='why-cureus-body'>
            <div className='large-6 medium-6 small-12 columns'>
              <h3>Collaborative Channels</h3>
              <img src="https://public.cureus.com/icons/channelicon.png" />
              <p>Our channels empower medical organizations to publish and <a href='/article_promotion'>promote research</a> in their respective fields. By creating a branded hub for <a href='/reviewer_guide#!/overview/why-review-with-cureus'>peer-reviewed articles</a>, Cureus facilitates international exposure and networking opportunities. <a href='/channels'>Cureus channels</a> elevate the visibility of your organization’s research.</p>
            </div>
            <div className='large-6 medium-6 small-12 columns'>
              <h3>Curated Collections</h3>
              <img src="https://public.cureus.com/icons/collecicon.png" />
              <p>Cureus offers curated collections of articles tailored to specific medical specialties. These collections streamline research and encourage innovation by presenting organized compilations of the latest findings. Join us in advancing medical knowledge by contributing to <a href='/collections'>Cureus collections</a> in your specialty.</p>
            </div>
          </div>
        </div>
        <Filter 
          isMenuOpen={isFilterMenuOpen}
          setFilterMenuOpen={setIsFilterMenuOpen}
          contents={specialtiesForFilterTab}
          pills={[]}
        />
      </div>
    </div>
  )
}

export default Main;