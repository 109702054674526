import React, {useState, useEffect} from 'react';
import PictureQuizCard from './PictureQuizCard';
import Pages from '../Shared/Pagination/Pages';

const Show = ({}) => {

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [pictureQuizCards, setPictureQuizCards] = useState([]);
  const [paginationData, setPaginationData] = useState({});

  useEffect(() => {
    fetchPictureQuizzes();
  }, [page])

  const fetchPictureQuizzes = () => {
    $.ajax({
      type: "GET",
      url: "/picture_quizzes",
      dataType: "JSON",
      data: { page: page, per_page: perPage },
      success: function(res) {
        setPictureQuizCards(res.cards);
        setPaginationData(res.paginationData);
      }
    })
  }

  const renderPictureQuizCards = () => {
    return (
      <div className={"large-12 medium-12 small-12 columns picture-quiz-cards-section show-page"}>
        {pictureQuizCards.map((pictureQuiz) => {
          return <PictureQuizCard key={pictureQuiz.id} pictureQuiz={pictureQuiz} showPageCard={true} />;
        })}
      </div>
    )
  }

  return (
    <>
      <hr className="show-page-hr" />
      <div className="picture-prognosis-gallery">
        <div className='header-container'>
          <h3 className="header">Picture Prognosis Library</h3>
          <a className="button special view-btn-container show-page show-for-medium-up" onClick={() => window.location.href = '/picture_quizzes'}>
            <a className="more-quizzes-text">More Quizzes</a>
            <i className="fa fa-arrow-right" />
          </a>
        </div>

        {renderPictureQuizCards()}

        <div className="large-11 medium-11 small-12 columns pagination-container show-page">
          <Pages
            paginationData={paginationData}
            handlePageSelect={setPage}
            pagesType={"pictureQuiz"}
          />
        </div>
      </div>
    </>
  );
};

export default Show;