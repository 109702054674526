import React, {useState, useEffect} from 'react';
import Filter from '../Shared/FilterTab/Filter';
import SearchBar from '../Shared/SearchBar/SearchBar';
import PictureQuizCard from './PictureQuizCard';
import Pages from '../Shared/Pagination/Pages';

const Main = ({categories}) => {

  const [pictureQuizCards, setPictureQuizCards] = useState([]);
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
  const [savedCategories, setSavedCategories] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [isInitialLoad, setIsInitialLoad] = useState(true); 

  const renderPicturePrognosisCards = () => {
    if (pictureQuizCards.length > 0) {
      return (
        <div className={"large-12 medium-12 small-12 columns picture-quiz-cards-section "}>
          {pictureQuizCards.map((pictureQuiz) => {
            return <PictureQuizCard key={pictureQuiz.id} pictureQuiz={pictureQuiz} />;
          })}
        </div>
      )
    } else {
      return (
        <div className="no-results-container text-center">
          <h4>No Results Found</h4>
        </div>
      )
    }
  }

  return (
    <div className='row specialties-page'>
      <div className='large-11 medium-11 small-12 columns small-centered specialties-section'>
        <div className='header-container'>
          <h1 className='text-center'>Picture Prognosis Library</h1>
          <p className='picture-prognosis-sub-header'>Explore our library of picture prognosis quizzes to test your clinical knowledge and share with friends and colleagues!</p>
        </div>
        <SearchBar
          url={"/picture_quizzes"}
          placeHolder={"Search for a picture prognosis quiz."}
          specialtiesPage={true}
          setCards={setPictureQuizCards}
          isFilterMenuOpen={isFilterMenuOpen}
          setIsFilterMenuOpen={setIsFilterMenuOpen}
          page={"pictureQuiz"}
          isFilterRequired={true}
          setPaginationData={setPaginationData}
          perPage={perPage}
          paginationPage={page}
          isPaginationUsed={true}
        />

        {!isInitialLoad &&
          renderPicturePrognosisCards()
        }
        
        <Filter 
          isMenuOpen={isFilterMenuOpen}
          setFilterMenuOpen={setIsFilterMenuOpen}
          contents={categories}
          savedCategories={savedCategories}
          setSavedCategories={setSavedCategories}
          setCards={setPictureQuizCards}
          url={'/picture_quizzes'}
          page={"pictureQuiz"}
          pills={[]}
          paginationSetUp={true}
          setPaginationData={setPaginationData}
          perPage={perPage}
          currPaginationPage={page}
          setCurrPaginationPage={setPage}
          setIsInitialLoad={setIsInitialLoad}
        />
      </div>
      <div className="large-11 medium-11 small-12 columns pagination-container">
        <Pages
          paginationData={paginationData}
          handlePageSelect={setPage}
          pagesType={"pictureQuiz"}
        />
      </div>
    </div>
  )
}

export default Main;