import React from 'react';
import {Helmet} from 'react-helmet';

const References = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Journal Article Citations | Article References | Cureus</title>
        <meta name="description" content="Learn how to properly format journal article citations and references to qualify for free publication at Cureus. Connect with us and submit your research today." />
      </Helmet>

      <h1>References</h1>
      
      <h2>Reference Limits for Free Publication Eligibility</h2>
      <p>Articles are limited to the following number of references in order to remain eligible for free publication. Exceeding the below citation limits will disqualify your article from <a href='/author_guide#!/overview/free-publication'>free publication</a> in our journal, regardless of how many errors are found during our preliminary review.</p>
      <p>All review articles require the purchase of Preferred Editing regardless of reference total.</p>
      <div className='reference-limits row centered'>
        <div className='small-6 medium-offset-2 medium-2 columns reference-limit-item'>
          <div className='small-font blue'><strong>Original<br/>Articles</strong></div>
          <div className='large-font space-top-s'><strong>30</strong></div>
          <div className='small-font space-top-xxs'><strong>References</strong></div>
        </div>
        <div className='small-6 medium-2 columns reference-limit-item'>
          <div className='small-font blue'><strong>Technical<br/>Reports</strong></div>
          <div className='large-font space-top-s'><strong>20</strong></div>
          <div className='small-font space-top-xxs'><strong>References</strong></div>
        </div>
        <div className='small-6 medium-2 columns reference-limit-item'>
          <div className='small-font blue'><strong>Case<br/>Reports</strong></div>
          <div className='large-font space-top-s'><strong>20</strong></div>
          <div className='small-font space-top-xxs'><strong>References</strong></div>
        </div>
        <div className='small-6 small-centered medium-2 medium-uncentered columns end reference-limit-item last'>
            <div className='small-font blue pad-top-10'><strong>Editorials</strong></div>
            <div className='large-font pad-top-36'><strong>5</strong></div>
            <div className='small-font space-top-xxs'><strong>References</strong></div>
          </div>
      </div>

      <h2>DOIs Required if Available</h2>
      <p>The DOI (digital object identifier) is a string of numbers that uniquely identifies a published article. The DOI is permanently assigned to an article and provides a persistent link to current information about that article, including location.</p>
      <p>This enables readers to find the online article irrespective of any subsequent changes in the website structure, management responsibility of the journal in which it was first published, or the location of the website on which the journal is hosted.</p>
      <p><a hef='/'>Cureus</a> requires DOI numbers to be included in all references when available. If a DOI cannot be found, please add the article URL to the URL reference field instead.</p>
      
      <h2>Citing Unpublished Material</h2>
      <p><strong>Material that has not yet been accepted for publication and does not have a DOI should be noted as “unpublished data” and should not be included in the reference list.</strong> <a href='/articles'>Articles</a> that are pending publication elsewhere may be cited, however “(IN PRESS)” must be included at the end of the article title in the reference.</p>
      <p><strong>All material included in the reference list must have been previously published in citable journals or books.</strong> If you wish to reference materials in your articles that do not meet this specification, such as an <a href='/abstracts'>abstract</a> without DOI, <a href='/posters'>poster</a>, unpublished <a href='/author_guide#!/policies-and-procedures/data-citation-and-transparency'>data</a>, or personal communication, please include information about this source within the text of your article. </p>

      <h3>Formatting In-Text Journal Citations for Abstracts, Posters, and Unpublished Material</h3>
      <p>If a poster or abstract has a DOI, it should be treated as published material and cited in the reference list.</p>
      <p>To mention an abstract (with no DOI), poster or other unpublished material in the body of your article, please use the following format:</p> 
        <p>(Type of unpublished material: Author names. Title in Title Case. Meeting Title (if applicable); Date)</p>
      <p>If needed, repeated subsequent citations for the same item should be shortened to include first author name and date as follows: </p>
      <p>(Type of unpublished material: First author name, date).</p>
      <p>Preprints should be included in the reference list and cited accordingly and “[PREPRINT]” should be included after the article title in the corresponding reference.</p> 
      
      <h2>Reference Citations</h2>
      <p>References should be cited using square brackets. Reference citations may be placed mid-sentence or at the end of a sentence (but before the period). </p>
      <p>For example:</p>
      <ul className='disc indented-for-medium-up'>
        <li>This is an example sentence [1].</li>
      </ul>
      <p>If multiple references are cited in one sentence, please list them as follows to ensure your journal article citations are correctly formatted:</p> 
      
      <ul className='disc indented-for-medium-up'>
        <li>[1-4], [1,3], [5,9-12], etc. Footnotes are not permitted.</li>
      </ul>
      <p>Please number references in your medical research article in the order in which they are mentioned in the text; they should not be alphabetized. </p>
      <p>All co-authors must be cited when creating a reference unless there are more than seven authors. <u>If more than seven authors, insert “et al.” after the first three authors.</u> Author names should be formatted as seen in the examples below.</p>
      
      <h2>Reference Abbreviations</h2>
      <p> Journal names should be abbreviated according to the Index Medicus system. For more information, please refer to the <a href='http://www.icmje.org/recommendations/browse/manuscript-preparation/preparing-for-submission.html#g' target='_blank' rel='noreferrer'>International Committee of Medical Journal Editors: Recommendations for the Conduct, Reporting, Editing and Publication of Scholarly Work in Medical Journals.</a></p>
      
      <h2>Additional Formatting Requirements</h2>
      <p>Please adhere to our journal’s requirements for reference and citation formatting in your article. Submissions that do not follow these requirements will be required to purchase our <a href='#'>Preferred Editing service</a>. Please pay particular attention to the following:</p>
      <ul className='indented-for-medium-up ref-formatting'>
        <li className='warning space-bottom-xs'><strong>References missing required information (page numbers, title, authors, etc) will always be the responsibility of the author even if Preferred Editing has been purchased.</strong></li>
        <li className='warning space-bottom-xs'><strong>Do not link to a website that no longer exists. (Defunct journals may still be referenced.)</strong></li>
        <li className='warning space-bottom-xs'><strong>When referencing a book (chapter or whole), editors (if available), publisher and publisher location must be listed.</strong></li>
        <li className='warning space-bottom-xs'><strong>When referencing a journal article, only the first letter of the first word should be capitalized. (Note: Book titles should be listed in title case, i.e. the first letter of each word is capitalized.)</strong></li>
        <li className='warning space-bottom-xs'><strong>Website references must include an access date and the name of the citation (i.e. not just the URL).</strong></li>
        <li className='warning space-bottom-xs'><strong>List only the volume number of the journal. Issue and supplement numbers should not be included. If referencing a book chapter, please include the edition and volume numbers.</strong></li>
        <li className='warning space-bottom-xs'><strong>Please list the page numbers of the cited chapter or article.</strong></li>
        <li className='warning space-bottom-xs'><strong>The DOI number should be added to the end of the reference if available. The “DOI” abbreviation should not be included.</strong></li>
        <li className='warning'><strong>All references must include a DOI or URL unless neither exist.</strong></li>
      </ul>
      <p>Prior to converting your medical research article’s references, please ensure they are formatted as follows:</p>
      <div className='callout-box'>
        <h5>Article within a journal</h5>
        <p>
          <span>Yang SH, Du Y, Li XL, et al.: </span>
          <a href='https://dx.doi.org/10.1016/j.amjms.2017.03.032' target='_blank' rel='noreferrer'>Triglyceride to high-density lipoprotein cholesterol ratio and cardiovascular events in diabetics with coronary artery disease</a>.
          Am J Med Sci. 2017, 354:117-24.
          <a href='https://dx.doi.org/10.1016/j.amjms.2017.03.032' target='_blank' rel='noreferrer'>10.1016/j.amjms.2017.03.032</a>
        </p>
        </div>
      <div className='callout-box'>
        <h5>Website</h5>
        <p>
          <a href='https://www.washingtonpost.com/news/arts-and-entertainment/wp/2013/03/26/fda-beware-of-black-henna-tattoos/' target='_blank' rel='noreferrer'>FDA: Beware of 'black henna' tattoos</a>. (2013). Accessed: September 24, 2020: <a href='https://www.washingtonpost.com/news/arts-and-entertainment/wp/2013/03/26/fda-beware-of-black-henna-tattoos/' target='_blank' rel='noreferrer'>https://www.washingtonpost.com/news/arts-and-entertainment/wp/2013/03/26/fda-beware-of-black-henna-tattoos/</a>
        </p>
      </div>
      <div className='callout-box'>
        <h5>Video within a journal</h5>
        <p>
          <span>Lavezzo MM, Sakata VM, Morita C, et al.: </span>
          <a href='https://pubmed.ncbi.nlm.nih.gov/27008848/' target='_blank' rel='noreferrer'>Vogt-Koyanagi-Harada disease: review of a rare autoimmune disease targeting antigens of melanocytes</a>.
          Orphanet J Rare Dis. 2016, 11:29.
          <a href='https://pubmed.ncbi.nlm.nih.gov/27008848/' target='_blank' rel='noreferrer'>https://pubmed.ncbi.nlm.nih.gov/27008848/</a>.
        </p>
      </div>
      <div className='callout-box'>
        <h5>Whole issue of journal</h5>
        <p>
          <a href='https://www.govinfo.gov/app/details/STATUTE-124/STATUTE-124-Pg119' target='_blank'  rel='noreferrer'>The Patient Protection and Affordable Care Act in Statute</a>.
          2010, 124:119-1024.
        </p>
      </div>
      <div className='callout-box'>
        <h5>Complete book</h5>
        <p>
          <span>Bailey LH, Bailey EZ: </span>
          <a href='https://scholar.google.com/scholar?q=intitle:Hortus%20Third%20:%20A%20Concise%20Dictionary%20of%20Plants%20Cultivated%20in%20the%20United%20States%20and%20Canada' target='_blank' rel='noreferrer'>Hortus Third: A Concise Dictionary of Plants Cultivated in the United States and Canada</a>.
          Macmillan, New York; 1976.
        </p>
      </div>
      <div className='callout-box'>
        <h5>Book chapter or article within a book</h5>
        <p>
          <span>Gogia S: </span>
          <a href='https://dx.doi.org/10.1016/C2017-0-01090-X' target='_blank' rel='noreferrer'>Rationale, history, and basics of telehealth</a>.
          Fundamentals of Telemedicine and Telehealth. Gogia S (ed): Elsevier B.V., Amsterdam, Netherlands; 2019. 11-34.
          <a href='https://dx.doi.org/10.1016/C2017-0-01090-X' target='_blank' rel='noreferrer'>10.1016/C2017-0-01090-X</a>
        </p>
      </div>
      <div className='callout-box'>
        <h5>Book with institutional author</h5>
        <p>
          <span>Seffinger MA: </span>
          <a href='https://scholar.google.com/scholar?q=intitle:Foundations%20of%20Osteopathic%20Medicine.%20Philosophy,%20Science,%20Clinical%20Applications%20and%20Research' target='_blank' rel='noreferrer'>Foundations of Osteopathic Medicine. Philosophy, Science, Clinical Applications and Research</a>.
          Wolters Kluwer, Philadelphia, PA; 2018.
        </p>
      </div>
      <p> If you choose to use reference management software for journal article citations, Cureus recommends <a href='https://endnote.com/' target='_blank' rel='noreferrer'>EndNote</a> for easy management and formatting of citations and reference lists. <a href='https://endnote.com/style_download/cureus-2/' target='_blank' rel='noreferrer'>You can download Cureus&apos; preferred style here.</a></p>
    </React.Fragment>
  )
}

export default References;