import { is } from 'date-fns/locale';
import React, {useState, useEffect, useRef} from 'react';

const SearchBar = ({
  url, 
  placeHolder, 
  setCards, 
  isFilterMenuOpen, 
  setIsFilterMenuOpen, 
  page, 
  isFilterRequired, 
  setIsSearchResults, 
  isPaginationUsed=false, 
  isSpinnerUsed=false,setSpinning, 
  additionalParams = {},
  setPaginationData,
  perPage,
  paginationPage
}) => {

  const [term, setTerm] = useState('');
  const firstRenderRef = useRef(true);

  useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
    } else {
      if (isSpinnerUsed) {
        setSpinning(true);
      }
      fetchContents();
    }
  }, [term]);


  const buildParams = () => {
    return page === 'pictureQuiz'
      ? { page: paginationPage, per_page: perPage }
      : { additionalParams };
  };

  const fetchContents = () => {
    $.ajax({
      type: 'GET',
      url: url,
      dataType: 'json',
      data: {
        ...buildParams(),
        searchTerm: term,
      },
      success: function(res) {
        if (isPaginationUsed) {
          if (page === 'pictureQuiz') {
            setPaginationData(res.paginationData);
          } else {
            setIsSearchResults(Boolean(term));
          }
        }
        setCards(res.cards);
        if (isSpinnerUsed) {
          setSpinning(false);
        }
      }
    });
  }

  const handleChange = (event) => {
    const debounced = _.debounce(function() {
      setTerm(event.target.value)
    }, 1000);
    debounced();
  }

  const openFilterMenu = () => {
    setIsFilterMenuOpen(!isFilterMenuOpen);
  }

  const renderFilterButton = () => {
    return (
      <a className="filter-button-container" onClick={openFilterMenu}>
        <img className="filter-btn-icon" src="https://public.cureus.com/new-nav-bar/filter-icon.png"/>
        <p className="filter-text">Filter</p>
      </a>
    )
  }

  const renderClass = () => {
    if (page === 'channels') {
      return 'channel-margin-top';
    } else if (page === 'pictureQuiz') {
      return 'picture-quiz-margin-bottom';
    } else {
      return '';
    }
  }

  return (
    <div className="small-collapse">
      <div
        className={`small-12 columns pages-searchbar-container ${renderClass()} ${term ? 'has-content' : ''}`}
        data-placeholder={placeHolder}
      >
        <img className="search-icon" src="https://public.cureus.com/new-nav-bar/search-icon.png"/>
        <input
          className='all-searchbar'
          placeholder={placeHolder}
          onChange={(e)=> {handleChange(e)}}
          aria-label={placeHolder}
        />
        {isFilterRequired && renderFilterButton()}
      </div>
    </div>
  )
}

export default SearchBar;