import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';

const Introduction = ({setFooterText, setIncludeDefault}) => {

  useEffect(() => {
      setFooterText(
        <p>
          Create a free Cureus account and help revolutionize the way medical research is curated and disseminated. Join us in our efforts to promote timely accessibility to scientific information for the advancement of medical science.
        </p>
      );
      setIncludeDefault(false)
     }, [])

  return (
    <React.Fragment>
      <Helmet>
        <title>Guest Editor | Volunteer Editing for Collections | Cureus</title>
        <meta name='description' content='Become a guest editor at Cureus. Enhance your academic profile through volunteer editing, curate impactful collections, and promote research. Join us today!' />
      </Helmet>
      <h1>An Introduction to Cureus Collections</h1>

      <h2>What is a Cureus Collection?</h2>
      <p>
        Cureus Collections are curated collections of peer-reviewed articles focused on a specific topic. Think of them as our version of special issues! There are two types of Cureus Collections: collections created and managed by the journal’s <a href='/editorial_board'>editorial staff</a>, and collections proposed, created, and managed by one or more guest editors.
      </p>

      <h2>What are the Responsibilities of a Collection Guest Editor?</h2>
      <p>
      Volunteer editing involves reviewing article submissions that the authors have submitted for inclusion in a specific collection. When creating and submitting an article draft to <a href='/'>Cureus</a>, the submitting <a href='/author_guide'>author</a> has the opportunity to select a specific collection that they think would be a good fit for their article. 
      </p>

      <p>
      If the article is accepted for peer review and eventually approved for publication, it will be queued for review and approval or rejection by the designated collection’s guest editor. Approved articles will appear within the collection, while rejected articles will still be published, just not in the designated collection.
      </p>

      <h3>Enhancing Editorial Efficiency</h3>
      <p>We want the guest editor’s job to be as easy as possible. As a result, volunteer editing is limited to making a binary choice: to include or exclude articles submitted to their collection.</p>

      <p>Cureus is known for our fast and efficient <a href='/about_publishing'>publication process</a> and we don’t want to add additional steps and delays for articles submitted to collections. While we may expand the scope of guest editor responsibilities in the future, for now, we are keeping it simple.</p>

      <h2>What are the Benefits of Volunteering as a Guest Editor?</h2>
      <p>
        Performing volunteer editing for a Cureus Collection is a great opportunity to position yourself as a thought leader in your field and gain invaluable academic experience for your CV. <a href='/promotion_guide'>Share</a> and <a href='/article_promotion'>promote</a> your collection with colleagues near and far to drive engagement and raise awareness of your collaboration with the Cureus Journal of Medical Science!
      </p>

      <p>Cureus handles everything related to article processing and publishing including preliminary quality checks, <a href='/reviewer_guide'>peer review management</a>, and <a href='/cureus_editing_services'>copy editing</a> so guest editors can focus on two vital ingredients for a successful Cureus Collection:</p>
      <ol className='decimals indented-for-medium-up'>
        <li>Selecting the highest quality and most relevant articles for inclusion in your collection</li>
        <li>Promoting your collection with colleagues across your institution and any relevant professional organizations or societies</li>
      </ol>
    </React.Fragment>
  )
  
}

export default Introduction