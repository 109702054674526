var createReactClass = require('create-react-class');
import React from 'react';
import FlagModal from './FlagModal';
import CommentForm from './CommentForm';
import ConfirmationModal from '../ConfirmationModal';
import Modal from '../Modal';
import LikesModal from '../Likes/Modal';
import LikeButton from '../Shared/LikeButton';
import { decodeHtml } from '../../Cureus';

const Comment = createReactClass({
  getInitialState: function() {
    return ({
      mode: "view",
      commentEditText: this.props.comment.body,
      permission: this.props.signedIn,
      disableReply: this.props.disableReply
    })
  },

  componentDidMount: function() {
    this.reflowDropdown()
  },

  reflowDropdown: function() {
    $(document).foundation('dropdown', 'reflow');
  },

  renderCommentActions: function() {
    if (this.props.comment.commentOwner) {
      return (
        <div style={{display:"inline-block", position: 'relative'}}>
          <a data-dropdown={"comment-actions-" + this.props.comment.id} data-options="is_hover:true; align:right;" onMouseLeave={this.reflowDropdown}>
            <i className="fas fa-ellipsis-h"/>
          </a>
          <ul className={"tiny comment-actions f-dropdown " + (this.props.comment.level === 2 ? 'reply' : '') }data-dropdown-content id={"comment-actions-" + this.props.comment.id}>
            <li>
              <a className="edit-comment" onClick={this.openEditForm} data-comment-edit={this.props.comment.id} id={"comment-text-" + this.props.comment.id}><i className="fa fa-pencil-alt"/> Edit</a>
            </li>
            <li>
              <a className="destroy-comment" onClick={this.handleDelete} data-comment-edit={this.props.comment.id} id={"comment-text-" + this.props.comment.id}><i className="far fa-trash-alt"/> Delete</a>
            </li>
          </ul>
        </div>
      )
    }
  },


  renderCommentField: function() {
    var plainText = this.state.commentEditText.replace(/<\/?[^>]+(>|$)/g, "")
    if (this.state.mode == "edit") {
      return (
        <div className="comment_text edit">
          <div className="row">
            <div className="large-12 columns comment_form_inner">
              <form acceptCharset="UTF-8" action="/comments/2766" method="post">
                <textarea ref="edit" id="comment_body" required onKeyUp={this.closeEditForm} onChange={this.handleEditKeyPress} value={plainText} onFocus={(e)=>e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)}/>
                <div className="right">
                  <a className="button secondary comment-cancel-button" onClick={this.closeEditForm}>Cancel</a>
                  <input type="button" className="button primary" onClick={this.updateComment} value="Save" />
                </div>
              </form>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <div className="comment_text">
            <div key={this.props.comment.id} dangerouslySetInnerHTML={{__html: decodeHtml(this.props.comment.body)}} />
          </div>
          <div className={ this.props.comment.level == 0 ? "reply_main" : "reply_sub" } data-comment-footer={ this.props.comment.id }>
            {this.renderCommentViewActions()}
            {this.renderReplyField()}
            {this.renderPendingText()}
          </div>
        </div>
      )
    }
  },

  handleReplyButtonClick: function() {
    this.changeCommentMode("reply")
  },

  handleCancelReply: function() {
    this.changeCommentMode("view", this.reflowDropdown)
  },

  openEditForm: function() {
    var self = this;
    self.changeCommentMode("edit", function() {
      $(self.refs.edit).focus()
    });
  },

  closeEditForm: function(e) {
    if (e.key === "Escape" || e.target.innerHTML === 'Cancel') {
      this.setState({ mode: "view" }, function() {
        this.reflowDropdown()
      })
    }
  },

  changeCommentMode: function(state, callback) {
    this.setState({
      mode: state
    }, function() {
      if (callback) {callback()}
    })
  },

  handleEditKeyPress: function(e) {
    this.setState({ commentEditText: e.target.value })
  },

  updateComment: function() {
    var params = { comment: this.props.comment }
    params.comment.body = this.state.commentEditText

    this.props.editComment(this.props.comment.resourcePath, params, this.callbackAfterCommentAjax)
  },

  handleDelete: function() {
    Modal.open({
      children: <ConfirmationModal
                  handleSubmit= {this.deleteComment} />
    });
  },

  deleteComment: function() {
    this.props.deleteComment(this.props.comment.resourcePath, this.props.deleteCallback)
  },

  renderCommentViewActions: function() {
    if (this.state.mode == "view") {
      return (
        <div className='reply-section'>
          <LikeButton
            currentUserId={this.props.currentUserId}
            likableId={this.props.comment.id}
            initialLikeId={this.props.comment.likeId}
            initialLikeCount={this.props.comment.likeCount}
            likableType={"Comment"}
          />
          {this.renderReplyButton()}
          {this.renderFlag()}
          {this.renderCommentActions()}
        </div>
      )
    }
  },

  renderFlag: function() {
    if(this.props.comment.commentOwner) {
      return;
    } else if (this.props.comment.canFlag) {
      return (
        <a className="flag" onClick={this.handleFlagging} data-reveal-id={"report_comment_" + this.props.comment.id} >
          <img className="flag-icon" src="https://public.cureus.com/article-show-redesign/new-flag-icon.png" alt="flag" />
        </a>
      )
    }
  },

  handleFlagging: function() {
    Modal.open({
      children: <FlagModal
                  signedIn={this.props.signedIn}
                  canFlag={this.props.comment.canFlag}
                  comment={this.props.comment}
                   />
      })
  },

  renderReplyButton: function() {
    if (this.state.disableReply != true) {
      return <a className={"button home-orange reply-button"} onClick={this.handleReplyButtonClick} id={"reply_to_comment_" + this.props.comment.id} data-reply-to-comment={this.props.comment.id}> REPLY </a>
    }
  },

  callbackAfterCommentAjax: function() {
      this.setState({ mode: "view"})
  },

  handleBackgroundColor: function() {
    if(this.props.comment.unConfirmed) {
      return {backgroundColor: "#F8F8F8"}
    }
  },

  renderReplyField: function() {
    if (this.state.mode == "reply") {
      return(
        <CommentForm
          commentIds={this.props.commentIds}
          createComment={this.props.createComment}
          callback={this.callbackAfterCommentAjax}
          parentId={this.props.comment.id}
          signedIn = {this.props.signedIn}
          readonly = {this.props.readonly}
          commentableType= {this.props.commentableType}
          commentableId= {this.props.commentableId}
          commentablePublished = {this.props.commentablePublished}
          currentUserAvatar = {this.props.currentUserAvatar}
          currentUserQualifications = { this.props.currentUserQualifications }
          currentUserName = {this.props.currentUserName}
          handleCancelReply = {this.handleCancelReply}
          replyForm = {true}
          renderSpinner= {this.props.renderSpinner}
          />
      )
    }
  },

  renderPendingText: function() {
    if(this.props.comment.unConfirmed){
      return (
        <p className="pending-text">Your comment is pending email confirmation. Please check your email and follow the instructions. <a onClick={this.sendReminder}>Resend email.</a></p>
      )
    }
  },

  sendReminder: function() {
    var self = this;
    $.ajax("/mini_confirmations/" + self.props.commentableId + "/resend_confirmation_email", {
      dataType: "JSON",
      type: "GET"
    }).done(function(res) {
      Cureus.Flash.success('You will receive an email with instructions about how to confirm your account in a few minutes.')
    }).always(function() {
      $("html, body").animate({scrollTop: 0}, 1000);
    })
  },

  isNotReply: function() {
    return this.props.comment.level === 0
  },

  renderNameDate: function() {
    if(this.state.mode !== "edit") {
      return (
        <div className="profile-section">
          <div className="avatar">
            <a href={this.props.comment.userProfilePath} target="_blank">
              <div key={this.props.comment.id} dangerouslySetInnerHTML={{__html: this.props.comment.commentOwnerAvatar}}/>
            </a>
          </div>
          <div>
            <div>
              <span className="comment_user"><a href={this.props.comment.userProfilePath} target="_blank">{this.props.comment.full_name}</a>{this.props.comment.qualifications && ','}</span>
              <span style={{ color: "#83878E" }}>{this.props.comment.qualifications ? ` ${this.props.comment.qualifications}` : ''}</span>
            </div>
            <span className="comment_date">{this.props.comment.updateAt}</span>
          </div>
        </div>
      )
    }
  },

  renderComment: function() {
    return (
      <>
        <hr/>
        <div className={`comment_${this.props.comment.id}` + " comment comment_inner comment_index_" + this.props.comment.level + " " + this.props.comment.commentColorClass + (this.isNotReply() ? "" : " reply-comment")} style={this.handleBackgroundColor()}>
          <div className="small-10 medium-11 large-11 columns" style={{width: '100%', padding: '0 0'}}>
            {this.renderNameDate()}
            {this.renderCommentField()}
          </div>
        </div>
      </>
    )
  },

  handleCommentIndenting: function() {
    if(this.props.comment.level > 1) {
      return {
        marginLeft: (20 * this.props.comment.level - 20) + 'px'
      }
    }
  },

  render: function() {
    return (
      <>
        <div className={"row " + (this.props.pageStyle ? this.props.pageStyle : "")}>
          <div className={this.isNotReply() ? "" : "large-11 large-offset-1 medium-11 medium-offset-1 columns"}>
            <div className={"comment-container " + (this.isNotReply() ? "" : "border") + " comment_index_" + this.props.comment.level} style={this.handleCommentIndenting()}>
              {this.renderComment()}
            </div>
          </div>
        </div>
      </>
    )
  }
})

export default Comment;