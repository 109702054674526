var createReactClass = require('create-react-class');
import React from 'react';
import CommentForm from './CommentForm';
import Comment from './Comment';
import SplitDropDownSort from './SplitDropDownSort';
import { Cureus } from '../../Cureus';

const Main = createReactClass({
  getInitialState: function() {
    return ({
      spinning: true,
      readOnly: false,
      comments: [],
      limitRecordsPerRequest: 10,
      newlyCreatedTopLevelComment: null,
      newlyCreatedReplyComments: [],
      commentCount: 0,
      commentAmountOffset: 0,
      commentableType: this.props.commentableType,
      commentableId: this.props.commentableId,
      viewPort: this.props.viewPort,
      commentablePublished: this.props.commentablePublished,
      currentUserQualifications: this.props.currentUserQualifications,
      params: {
        comment: {
          commentable_type: this.props.commentableType,
          commentable_id: this.props.commentableId,
          }
      },
      sortBy: "asc"
    })
  },

  componentDidMount: function() {
    if (this.props.openComments) {
      setTimeout(function() {
        document.getElementById('article-comments-section').scrollIntoView()
      }, 500)
    }
    this.getComments();
    Cureus.SplitButton.init();
  },

  getComments: function() {
    this.setState({spinning: true})
    var self = this;

    $.ajax({
      url: self.getCommentsPath(),
      type: "GET",
      data: self.state.params,
      dataType: "json",
      success: function(data) {
        self.setState({
          comments: data.comments,
          commentCount: data.totalCommentCount,
          spinning: false,
        }, function() {
            self.reflowFoundationDropDown()
          }
        )
        $(document).foundation('reflow');
      }
    })
  },

  getCommentsPath: function() {
    return this.props.commentsIndexPath + `?&sort_by=${this.state.sortBy}`
  },

  loadMoreComments: function() {
    this.setState({spinning: true})
    var self = this;
    let params = {...self.state.params, offset: (self.state.commentAmountOffset + self.state.limitRecordsPerRequest)}
    $.ajax({
      url: self.getCommentsPath(),
      type: "GET",
      data: params,
      dataType: "json",
      success: function(data) {
        self.setState({
          comments: self.filterOutTopLevelCommentAndConcat(data),
          commentCount: data.totalCommentCount,
          spinning: false,
          commentAmountOffset: self.state.commentAmountOffset + self.state.limitRecordsPerRequest,
        })
        $(document).foundation('reflow');
      }
    })
  },

  filterOutTopLevelCommentAndConcat:function(data) {
    return this.state.comments.concat(this.filterOutTopLevelComment(data))
  },

  filterOutTopLevelComment: function(data) {
    var self = this;
    if (self.state.newlyCreatedTopLevelComment && (data.totalCommentCount > self.state.limitRecordsPerRequest)) {
      return data.comments.filter( (comment) => {
        return comment.id != self.state.newlyCreatedTopLevelComment.id
      })
    } else {
      return data.comments
    }
  },

  reflowFoundationDropDown: function() {
    $(document).foundation('dropdown', 'reflow');
  },

  createComment: function(params, createCommentCallback) {
    var self = this;
    $.ajax({
      url: self.props.commentsCreatePath,
      type: "POST",
      data: {...params, sort_by: self.state.sortBy},
      dataType: "json",
      success: function(data) {
        self.setTopLevelCommentState(data.comment)
        self.setCommentsState(data.comments)
        self.setState({
          commentCount: data.totalCommentCount,
          commentAmountOffset: data.comments.length,
          commentAmountOffset: self.state.commentAmountOffset + data.addOffset
        })
        // needed for tool tips to work for newly created comment
        $(document).foundation('reflow');
        self.scrollToComment(data.comment)
        if(createCommentCallback) { createCommentCallback() }
        document.getElementById("comment_body").style.height = "100px";
      },
      error: function() {
        window.location.reload();
      }
    })
  },

  setTopLevelCommentState: function(comment) {
    if(comment.level === 0) { this.setState({newlyCreatedTopLevelComment: comment})}
  },

  setCommentsState: function(comments) {
    if (comments.length) {
      this.setState({comments: comments}, () => {
        this.reflowFoundationDropDown()
      })
    }
  },

  scrollToComment: function(comment) {
    let commentDiv = $(`.comment_${comment.id}`)
    if (comment.level > 0 || comment.level == 0 && (this.state.commentCount <= this.state.limitRecordsPerRequest)) {
      $("html, body").animate({scrollTop: $(commentDiv).offset().top - 270})
    }
  },

  editComment: function(path, params, callback) {
    var self = this;
    $.ajax({
      url: path,
      type: "PUT",
      data: params,
      dataType: "json",
      success: function(comment) {
        self.handleEditSuccess(comment)
        if (callback) {callback()};
      }
    })
  },

  handleEditSuccess:function(comment) {
    if(this.state.newlyCreatedTopLevelComment.id === comment.id) {
      this.setState({newlyCreatedTopLevelComment: comment})
    } else {
      this.getComments();
    }
  },

  deleteComment: function(path, deleteCommentCallback) {
    var self = this;
    $.ajax({
      url: path,
      type: "DELETE",
      dataType: "json",
      success: function() {
        self.getComments();
        if(deleteCommentCallback) { deleteCommentCallback() }
      }
    })
  },

  handleDeletingNewlyCreatedTopLevelComment: function() {
    this.setState({newlyCreatedTopLevelComment: null})
  },

  renderComments: function() {
    var self = this;
    return this.state.comments.map(function(comment) {
      return (
        <Comment
        commentIds={self.commentIds()}
        renderSpinner={self.renderSpinner}
        comment={comment}
        key={comment.id}
        signedIn={self.props.signedIn}
        currentUserId={self.props.currentUserId}
        createComment={self.createComment}
        editComment={self.editComment}
        deleteComment={self.deleteComment}
        readonly = {self.props.readonly}
        commentablePublished = {self.state.commentablePublished}
        commentableType= {self.state.commentableType}
        commentableId= {self.state.commentableId}
        currentUserAvatar = {self.props.currentUserAvatar}
        currentUserName = {self.props.currentUserName}
        currentUserQualifications = {self.props.currentUserQualifications}
        viewPort={self.props.viewPort}
        pageStyle={self.props.pageStyle}
        />
      )
    })
  },

  commentIds: function() {
    return this.state.comments.map( (comment) => {return comment.id})
  },

  renderSpinner: function() {
    if(this.state.spinning) {
      return (
        <div className='comment-spinner-container'>
          <img src='https://public.cureus.com/ajax-loader.gif'/>
        </div>
      )
    }
  },

  shouldDisable: function() {
    let increment;
    if (this.state.newlyCreatedTopLevelComment && this.state.commentCount > this.state.limitRecordsPerRequest ) {
      increment = 1
    } else {
      increment = 0
    }
    return ((this.state.comments.length + increment) === this.state.commentCount);
  },

  handleSort: function(value) {
    $.ajax("/amplitudes", {
      dataType: "json",
      type: "POST",
      data: {
              event_type: "commentSortClicked",
              user_id: this.props.currentUserId
            }
    })
    this.setState({
      sortBy: value,
      commentAmountOffset: 0,
      newlyCreatedTopLevelComment: null,
    }, () => {this.getComments()})
  },

  render: function () {
    return (
      <div id="comments_list" className="comments_list">
        <div style={{ display: 'flex', alignItems: 'center'}}>
          {this.props.commentableType === "PictureQuiz" ? <h3 className="pp-reg reg">Responses</h3> : <h3 className="section-header">{this.props.title || "Comments"}</h3>}
          <h3 className='count'>{this.state.commentCount}</h3>
          <SplitDropDownSort
            handleSort={this.handleSort}
            sortBy={this.state.sortBy}
            />
        </div>
        <hr/>
        <CommentForm
          commentIds={this.commentIds()}
          createComment={this.createComment}
          signedIn = {this.props.signedIn}
          commentablePublished = {this.state.commentablePublished}
          readonly = {this.props.readonly}
          commentableType = {this.state.commentableType}
          commentableId = {this.state.commentableId}
          currentUserAvatar = {this.props.currentUserAvatar}
          currentUserQualifications = {this.props.currentUserQualifications}
          currentUserName = {this.props.currentUserName}
          signInPath = {this.props.signInPath}
          signUpPath = {this.props.signUpPath}
          openComments ={this.props.openComments}
          replyForm = {false}
          renderSpinner= {this.renderSpinner}
          pageStyle={this.props.pageStyle}
        />
        {this.state.newlyCreatedTopLevelComment && (this.state.commentCount > this.state.limitRecordsPerRequest) &&
          <Comment
            renderSpinner= {this.renderSpinner}
            comment={this.state.newlyCreatedTopLevelComment}
            key={this.state.newlyCreatedTopLevelComment.id}
            signedIn={this.props.signedIn}
            createComment={this.createComment}
            editComment={this.editComment}
            deleteComment={this.deleteComment}
            deleteCallback={this.handleDeletingNewlyCreatedTopLevelComment}
            readonly = {this.props.readonly}
            commentablePublished = {this.state.commentablePublished}
            commentableType= {this.state.commentableType}
            commentableId= {this.state.commentableId}
            currentUserAvatar = {this.props.currentUserAvatar}
            currentUserName = {this.props.currentUserName}
            viewPort={this.props.viewPort}
            disableReply={true}
            pageStyle={this.props.pageStyle}
          />
        }
        {this.renderComments()}
        <div className='row load-more-container'>
          <a className={(this.shouldDisable() ? 'button stencil-blue disabled' : 'button stencil-blue')}  onClick={this.loadMoreComments} disabled={this.shouldDisable()}>
            LOAD MORE
          </a>
        </div>
      </div>
    )
  }
})

export default Main;