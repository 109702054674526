import React, { useState, useEffect } from 'react';

const AnswerBarRow = ({answer, percent, percentNumber, userAnsweredId, correctAnswerId, handleAnswer, showAnswer}) => {

  const renderRadioBtnIcons = () => {
    // When user clicked "view answer" button without clicking any answers
    if (showAnswer && !userAnsweredId) {
      if (answer.correct) {
        return <i className='fas fa-check-circle' />;
      } else {
        return <div className='circle-dot'/>;
      }
    }
    // When user clicked "view answer" after wrong answer
    if (showAnswer) {
      if (answer.correct) {
        return <i className='fas fa-check-circle' />;
      } else if ((userAnsweredId == answer.id) && !answer.correct) {
        return <i className='fas fa-times-circle' />;
      } else {
        return <div className='circle-dot'/>;
      }
    }
    // When the user either answers incorrectly or answers correctly 
    // on their first attempt without clicking "view answer" for both cases
    if (!showAnswer) {
      if (userAnsweredId == answer.id && answer.correct) {
        return <i className='fas fa-check-circle' />;
      } else if ((userAnsweredId == answer.id) && !answer.correct) {
        return <i className='fas fa-times-circle' />; 
      } else {
        return <div className='circle-dot'/>;
      }
    }
  }

  const renderAnswerBar = () => {
    if ((showAnswer && !userAnsweredId) || (showAnswer && userAnsweredId) || (!showAnswer && typeof userAnsweredId === 'number')) {
      return undefined;
    } else {
      return handleAnswer(answer.id);
    }
  }

  const renderHover = () => {
    if (!userAnsweredId && showAnswer) {
      return '';
    } else if (!userAnsweredId) {
      return 'unanswered';
    } else {
      return '';
    }
  }

  const renderStats = () => {
    if (userAnsweredId == correctAnswerId || showAnswer) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <div className={'individual-bar-container ' + renderHover()} onClick={renderAnswerBar}>
      {renderRadioBtnIcons()}
      <p className='text-in-percent-bar'>{answer.answer}</p>
      {renderStats() && <div className="answer" style={{width: percent}} />}
      {renderStats() && <p className='percent-text'>{percentNumber}</p>}
    </div>
  )
}

export default AnswerBarRow;