import React from 'react';
import { Helmet } from 'react-helmet';

const AcceptedArticleTypes = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Cureus Article Types | Open Access Medical Journal | Cureus</title>
        <meta name="description" content="Learn about the article types Cureus publishes. Submit medical case reports, editorials, review articles, and original research to our Open Access journal." />
      </Helmet>
      <h1>Accepted Article Types</h1>
      <p>Cureus accepts and publishes five different article types: original research articles, review articles, medical case reports, technical reports, and medical editorials. Each article type must include the sections listed below to be considered for publication. Only original articles may include abstract subheadings. <a href='/'>Cureus</a> does not accept or publish article commentaries or letters to the editor.</p>

      <h2>Free Publication Eligibility</h2>
      <p>Original articles, case reports, technical reports, and medical editorials that exceed the specified number of authors or <a href='/author_guide#!/author-instructions/references'>references</a> will no longer be eligible for free publication. Review articles require the purchase of Preferred Editing regardless of the number of authors or references. </p>
      <p>
        Please note that staying within the limits does not guarantee that your article will be accepted for <a href='/about_publishing'>free publication</a> as article types with too many errors will always require Cureus <a href='/cureus_editing_services'>Preferred Editing</a>.
      </p>
      
      <h2 className='space-top-m'>Original Article</h2>
      <div className='article-type-info row'>
        <div className='medium-offset-0 medium-8 columns'>
          <p>Original research articles report original research and are classified as primary literature. This is the most common type of journal manuscript used to publish full reports of data from research. Original articles may also be referred to as research articles or research, as this format is suitable for many fields and different types of studies.</p>
          <p>A case series with six or more cases is considered a retrospective study and must be submitted as an original article with a far more extensive study than a typical medical case report. Cureus is very selective regarding submissions that utilize survey-based methodologies concerning general knowledge, awareness, and attitudes without a clear scientific impact.</p>
        </div>
        <div className='small-offset-5 small-6 medium-offset-1 medium-3 columns sections'>
          <div className='small-font blue'><strong>SECTIONS</strong></div>
          <div className='article-section-list'>
            <div>Abstract</div>
            <div>Introduction</div>
            <div>Materials and Methods</div>
            <div>Results</div>
            <div>Discussion</div>
            <div>Conclusion</div>
            <div>References</div>
          </div>
        </div>
        <div className='small-12 columns centered'>
          <div className='small-font blue space-bottom-s'><strong>Free Original Articles Limited To</strong></div>
        </div>
        <div className='small-12 medium-offset-2 medium-4 columns centered'>
          <div className='large-font .space-bottom-xxs'><strong>10</strong></div>
          <div className='small-font'><strong>Authors</strong></div>
        </div>
        <div className='small-12 medium-offset-0 medium-4 columns centered end'>
          <div className='large-font .space-bottom-xxs'><strong>30</strong></div>
          <div className='small-font'><strong>References</strong></div>
        </div>
      </div>
      
      <h2 className='space-top-xxl'>Review Article</h2>
      
      <div className='article-type-info row'>
        <div className='medium-offset-0 medium-8 columns'>
          <p>Review articles give an overview of existing literature in a field, often identifying specific problems or issues and providing a balanced analysis derived from published work on the topic. These articles provide a comprehensive summary of research on the selected <a href='/collections'>medical research topic</a> and a perspective on the state of the field and where it is heading.</p>

          <p>Review articles submitted to Cureus can be one of two types, broadly speaking: literature reviews or <a href='/articles/217671-ten-steps-to-conduct-a-systematic-review#!/'>systematic reviews</a>. Bibliometric analysis and meta-analysis articles should also be submitted as review articles. Cureus does not accept review protocols or “brief” or “mini” reviews.</p>
        </div>
        <div className='small-offset-5 small-6 medium-offset-1 medium-3 columns sections'>
          <div className='small-font blue'><strong>SECTIONS</strong></div>
          <div className='article-section-list'>
            <div>Abstract</div>
            <div>Introduction and Background</div>
            <div>Review</div>
            <div>Conclusions</div>
            <div>References</div>
          </div>
        </div>
        <div className='small-12 columns centered'>
          <div className='small-font blue space-bottom-s'><strong>Preferred Editing Required for All Review Articles</strong></div>
        </div>
      </div>
      
      <h2 className='space-top-xxl'>Case Report</h2>
      <div className='article-type-info row'>
        <div className='medium-offset-0 medium-8 columns'>
          <p>Medical case reports present the details of real patient cases from medical or clinical practice. Cases may contribute significantly to the existing knowledge on the field or showcase less novel results. The report is expected to discuss the signs, symptoms, diagnosis, and treatment of a disease. A goal of case reports is to make other researchers aware of the possibility that a specific phenomenon might occur.</p>

          <p>A case series with five or fewer cases must be submitted as a case report. Any MRI/CT/X-ray/scan image mentioned in the case report must be included in the article unless it shows nothing of interest. Articles featuring both a case presentation and a literature review must be submitted as a medical case report. Autobiographical case reports must include a physician as the author or co-author.</p>
        </div>
        <div className='small-offset-5 small-6 medium-offset-1 medium-3 columns sections'>
          <div className='small-font blue'><strong>SECTIONS</strong></div>
          <div className='article-section-list'>
            <div>Abstract</div>
            <div>Introduction</div>
            <div>Case Presentation</div>
            <div>Discussion</div>
            <div>Conclusions</div>
            <div>References</div>
          </div>
        </div>
        <div className='small-12 columns centered'>
          <div className='small-font blue space-bottom-s'><strong>Free Case Reports Limited To</strong></div>
        </div>
        <div className='small-12 medium-offset-2 medium-4 columns centered'>
          <div className='large-font space-bottom-xxs'><strong>5</strong></div>
          <div className='small-font'><strong>Authors</strong></div>
        </div>
        <div className='small-12 medium-offset-0 medium-4 columns centered end'>
          <div className='large-font space-bottom-xxs'><strong>20</strong></div>
          <div className='small-font'><strong>References</strong></div>
        </div>
      </div>

      <h2 className='space-top-xxl'>Technical Report</h2>
      <div className='article-type-info row'>
        <div className='medium-offset-0 medium-8 columns'>
          <p>Technical reports are formal reports designed to convey technical information in a clear and easily accessible format. This type of article is typically divided into sections, thereby allowing different Cureus readers to access different levels of information.</p>

          <p>A technical report (also sometimes referred to as a scientific report) is a document that describes the process, progress, or results of technical or scientific research, or the state of a technical or scientific research problem. It might also include recommendations and conclusions of the research.
          </p>
        </div>
        <div className='small-offset-5 small-6 medium-offset-1 medium-3 columns sections'>
          <div className='small-font blue'><strong>SECTIONS</strong></div>
          <div className='article-section-list'>
            <div>Abstract</div>
            <div>Introduction</div>
            <div>Technical Report</div>
            <div>Discussion</div>
            <div>Conclusions</div>
            <div>References</div>
          </div>
        </div>
        <div className='small-12 columns centered'>
          <div className='small-font blue space-bottom-s'><strong>Free Technical Reports Limited To</strong></div>
        </div>
        <div className='small-12 medium-offset-2 medium-4 columns centered'>
          <div className='large-font space-bottom-xxs'><strong>5</strong></div>
          <div className='small-font'><strong>Authors</strong></div>
        </div>
        <div className='small-12 medium-offset-0 medium-4 columns centered end'>
          <div className='large-font space-bottom-xxs'><strong>20</strong></div>
          <div className='small-font'><strong>References</strong></div>
        </div>
      </div>
      
      <h2 className='space-top-xxl'>Editorial</h2>
      <div className='article-type-info row'>
        <div className='medium-offset-0 medium-8 columns'>
          <p>Medical editorials are opinion or perspective pieces on a relevant, timely topic. Cureus approves this article type only if submitted by senior clinicians or researchers with an established Cureus publishing history.</p>
        </div>
        <div className='small-offset-5 small-6 medium-offset-1 medium-3 columns sections'>
          <div className='small-font blue'><strong>SECTIONS</strong></div>
          <div className='article-section-list'>
            <div>Abstract</div>
            <div>Editorial</div>
            <div>References</div>
          </div>
        </div>
        <div className='small-12 columns centered'>
          <div className='small-font blue space-bottom-s'><strong>Free Editorials Limited To</strong></div>
        </div>
        <div className='small-12 medium-offset-2 medium-4 columns centered'>
          <div className='large-font space-bottom-xxs'><strong>5</strong></div>
          <div className='small-font'><strong>Authors</strong></div>
        </div>
        <div className='small-12 medium-offset-0 medium-4 columns centered end'>
          <div className='large-font space-bottom-xxs'><strong>5</strong></div>
          <div className='small-font'><strong>References</strong></div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default AcceptedArticleTypes;