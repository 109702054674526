import React, { useState, useEffect } from 'react';
import Widget from "../StatsWidget/Widget"

const Dashboard = ({dashboardPath, setSpinning}) => {
  const [data, setData] = useState(null);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = () => {
    setSpinning(true)
    $.ajax({
      type: 'GET',
      url: dashboardPath,
      success: function(data) {
        setData(data)
        setSpinning(false)
      }
    });
  }

  const renderTogglerMessage = () => {
    if (data.channel_admins.length < 4 && data.deputy_editors.length < 4) {
      return
    }

    var caratClass = expanded ? "fa fa-angle-up" : "fa fa-angle-down"

    return (
      <div className="content-toggler" id="expand-trigger-wrap">
        <a onClick={ () => setExpanded(!expanded) }>
          <i className={ caratClass }></i>
        </a>
      </div>
    )
  }

  const renderEditors = (editors) => {
    let newEditors = !expanded ? editors.slice(0, 3) : editors
    return newEditors.map(editor => {
      return (
        <div className="row editor-row" key={editor.id}>
          <div className="small-5 columns no-padding" key={editor.id}><a href={editor.user_path} target="_blank">{editor.full_name}</a></div>
          <div className="small-7 columns offset-1 no-padding" key={editor.id+1}><a href={`mailto:${editor.email}`}>{editor.email}</a></div>
        </div>
      )
    })
  }

  const renderWidgetStats = () => {
    if(data) {
      return (
        <div className="widget-container">
          <Widget {...JSON.parse(data.publishedContent)} isChannelDashboard={true} />
          <Widget {...JSON.parse(data.contentEngagement)} isChannelDashboard={true} displayFlex={true}/>
          <Widget {...JSON.parse(data.channelEditorActivity)} isChannelDashboard={true} />
        </div>
      )
    }
  }

  const publishedContractDataText = () => {
    const { publishedFromContractDate, yearlyContractedArticles, formattedDateRange } = data;
    if (typeof publishedFromContractDate === 'number') {
      const totalPublished = publishedFromContractDate > yearlyContractedArticles ? <strong>{publishedFromContractDate}</strong> : publishedFromContractDate
      return (
        <p>{totalPublished} out of {yearlyContractedArticles} articles published in current contract period ({formattedDateRange})</p>
      )
    }
  }

  if (!data) {
    return <div/>
  } else {
    return (
      <React.Fragment>
        <div className="dashboard-container">
          <div className="row">
            <div className="small-12 columns">
              <h3 className="dashboard-stat-title">{data.name}</h3>
            </div>
          </div>
          <div className="row channel-metrics-container">
            <div  className="small-3 columns border-right">
              <h6>Channel Overview</h6>
              {data.published_at &&
                <p>
                  Launched {data.published_at}
                </p>
              }
              {publishedContractDataText()}
              <p>
                {data.published} total published
              </p>
              <p>
                {data.active} in progress
              </p>
            </div>
            <div className="small-9 columns">
              <div className="row admin-editors-container">
                <div className="small-6 columns border-right pad channel-administrators" >
                  <h6>Channel Administrators</h6>
                  <div>
                    {renderEditors(data.channel_admins)}
                  </div>
                </div>

                <div  className="small-6 columns pad">
                  <h6>Deputy Editors</h6>
                  <div>
                    {data.deputy_editors && renderEditors(data.deputy_editors)}
                    {data.deputy_editors.length == 0 && <span>&#8212;</span>}
                  </div>
                </div>
              </div>

              {renderTogglerMessage()}

            </div>
          </div>
          {renderWidgetStats()}
        </div>
      </React.Fragment>
    )
  }
}

export default Dashboard;