import React, { useState, useEffect } from 'react';
import {
  HashRouter,
  Switch,
  Route
} from 'react-router-dom';
import Card from './Card';
import SearchBar from './SearchBar';
import Ad from '../Shared/Ad';
import SearchResults from './SearchResults';
import { Helmet } from 'react-helmet';
import Footer from './Footer';

const Root = ({ sections, featured, bannerAd, squareAd }) => {

  const [query, setQuery] = useState('');
  const defaultFooterText = (<p><a href='/registrations/sign_up' target='_blank
  '>Join us</a> and explore our resources. Our guides help authors, reviewers and editors make valuable contributions to the medical research community.</p>)

  useEffect(() => {
    if(typeof(bannerAd) !== 'object') {
      $('#banner-ad').append(bannerAd)
      $('#square-ad').append(squareAd)
    }
  }, []);

  function renderSections(split=null, type) {
    return sections.map(function(card, idx) {
      if(split == null || idx % 2 == split) {
        return <Card
                  key={ `card-${card.type}-${idx}-${type}` }
                  type={ card.type }
                  title={ card.title }
                  body={ card.body }
              />
      }
    })
  }

  function renderSectionsForLarge() {
    return (
      <div className='card-container large-4 large-offset-1 columns show-for-large-up'>
        { renderSections(null, 'large') }
      </div>
    )
  }

  function renderSectionsForSmallMedium() {
    return (
      <div className='card-container hide-for-large-up'>
        <div className='card-column-left'>
          { renderSections(0, 'left') }
        </div>
        <div className='card-column-right'>
          { renderSections(1, 'right') }
          <div className='featured-card-container bottom'>
            <Card
              key={ "featured-card-bottom" }
              type={ featured.type }
              label={ featured.label }
              title={ featured.title }
              body={ featured.body }
            />
          </div>
        </div>
      </div>
    )
  }

  function renderSearchResult() {
    return (
      <div className='cureus-page'>
        <div className='cureus-ad-wrapper page-banner-ad show-for-large-up' >
          {renderBannerAd()}
        </div>
        
        <div className='content-container page'>
          <div className='row nav-header'>
            <div className='page-nav-label large-4 columns'>
              <a href='#!'>The Cureus Guides</a>
              <i className="fas fa-chevron-right sm"/>
              <span className='blue'>Search results</span>
            </div>
            <div className='large-8 columns' style={{padding: 0}}>
              <SearchBar classes={ "page" } doSearch={ doSearch }/>
            </div>
          </div>

          <div className='row page-contents'>
            <div className='cureus-ad-wrapper page-square-ad show-for-large-up' >
              <Ad
                props={ squareAd }
                size={ "square" }
              />
            </div>

            <div className='page-body large-8 columns'>
              <SearchResults query={ query }/>
            </div>
          </div>
        </div>
      </div>
    )
  }

  function renderBannerAd() {
    if(typeof(bannerAd) === 'object') {
      return (
        <div className='cureus-ad-wrapper page-banner-ad show-for-large-up' >
          <Ad
            props={ bannerAd }
            size={ "banner" }
          />
        </div>
      )
    } else {
      return (
        <div id="banner-ad"/>
      )
    }
  }

  function renderSquareAd() {
    if(typeof(bannerAd) === 'object') {
      return (
        <div className='cureus-ad-wrapper page-square-ad show-for-large-up' >
          <Ad
            props={ squareAd }
            size={ "square" }
          />
        </div>
      )
    } else {
      return (
        <div id="square-ad"/>
      )
    }
  }

  function doSearch(evt) {
    if (evt.key == 'Enter' || evt.type == 'click') {
      var value = $("#search-input")[0].value;
      window.location.hash = '#!/search';
      setQuery(value);
    }
  }

  function renderRoot() {
    return (
      <div className='cureus-page'>
        <Helmet>
          <title>Author Guide | Reviewer Guide | Media Guide | Cureus</title>
          <meta name="description" content="Navigate the intricacies of publishing medical research articles with our author and reviewer guides. Connect with Cureus to submit, read, and rate articles." />
        </Helmet>
        <div className='hero-container'>
          <div className='hero'/>
          <div className='hero-content'>
            <div className='large-7 columns'>
              <div className='hero-header'>What do you want to know?</div>
              <SearchBar classes={ "root" } doSearch={ doSearch }/>
            </div>
            <div className='large-4 large-offset-1 columns show-for-large-up'>
              <div className='featured-card-container top'>
                <Card
                  key={ "featured-card-top" }
                  type={ featured.type }
                  label={ featured.label }
                  title={ featured.title }
                  body={ featured.body }
                />
              </div>
            </div>
          </div>
        </div>
        <div className='content-container'>
          <div className='root-contents large-7 columns'>
            <h1>Cureus Guides</h1>
            <p>Navigate the fundamental aspects of contributing to <a href='/'>Cureus</a> with our collection of reviewer, author and editor guides. Whether you’re submitting an article, accepting a peer review invitation or looking to learn more about channels and collections, we have the essential information you need.</p>
            <p><h2 className='guide-title-inline'>Author Guide:</h2> Refer to our <a href='/author_guide#!/overview/introduction'>author guide</a> to learn what types of medical research articles we accept and familiarize yourself with our rules and requirements. This guide outlines our rigorous peer review process, ethical standards, formatting requirements, and pre-acceptance stages. Use it to ensure your work meets our quality benchmarks.</p>
            <p><h2 className='guide-title-inline'>Media Guide:</h2> Find detailed instructions on effectively incorporating figures, tables, videos, and interactive models into your article with our <a href='/media_guide#!/overview/adding-media'>media guide</a>. This resource clarifies what is considered media, along with specific placement rules and step-by-step instructions for adding, moving, or removing media items.</p>
            <p><h2 className='guide-title-inline'>Reviewer Guide:</h2> Is this the first time you’re reviewing with Cureus? Find out what to expect with our <a href='/reviewer_guide#!/overview/why-review-with-cureus'>reviewer guide</a>. This guide provides insights into the review process and explains how to complete your review efficiently. Refer to this resource to learn how to help enhance the quality of new research.</p>
            <p><h2 className='guide-title-inline'>Academic Channels Guide:</h2> Read a detailed overview of how channels are organized and operated in our <a href='/academic_channels_guide#!/overview/introduction'>academic channels guide</a>. This guide includes an overview of the channel article publication process as well as channel editorial roles and responsibilities and frequently asked questions.</p>
            <p><h2 className='guide-title-inline'>Guest Editor Guide:</h2> This <a href='/guest_editor_guide#!/overview/introduction'>guest editor guide</a> is for established authors and academics who want to oversee their own collection of medical research articles. Introduce yourself to the concept of <a href='/collections'>Cureus Collections</a> and position yourself as a thought leader in your field.</p>
            <div className='cureus-ad-wrapper root-ad show-for-large-up'>
              {renderSquareAd()}
            </div> 
          </div>
          { renderSectionsForLarge() }
          { renderSectionsForSmallMedium() }
          <Footer text={defaultFooterText}/>
        </div>
      </div>
    )
  }

  return (
    <HashRouter hashType={ "hashbang" }>
      <Switch>
        <Route path="/search">
          { renderSearchResult() }
        </Route>
        <Route path="/">
          { renderRoot() }
        </Route>
      </Switch>
    </HashRouter>
  )

}

export default Root;