var createReactClass = require('create-react-class');
import React from 'react';

const MessageModal = createReactClass({
  getInitialState: function() {
    return {
      selected: [],
      disableSubmit: false
    }
  },

  handleSubmit: function() {
    this.setState({disableSubmit: true}, () => {
      let text = $('#message_text')[0].value;
      if (text) {
        $.ajax({
          type: 'POST',
          url: this.props.path,
          data: {
            selected: this.state.selected,
            message: text
          }
        }).done(function() {
          window.location.reload();
        })
      }
    })
  },

  handleCheck: function(e) {
    let currEditors = this.state.selected
    let id = e.target.name
    if(currEditors.includes(id)) {
      currEditors.splice(currEditors.indexOf(id), 1)
    } else {
      currEditors.push(id)
    }
    this.setState({selected: currEditors})
  },

  formatKey: function(key) {
    return key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
  },

  renderReceipients: function() {
    var self = this;
    return self.props.recipients.map(function(group) {
      return (
        <div className="message-editor-row">
          {Object.entries(group).map(([key, recipients]) => (
          <div key={key}>
            <h6>{self.formatKey(key)}</h6>
            {recipients.map(function(recipient) {
              return Object.entries(recipient).map(([name, id]) => (
                <label key={id}>
                  <input className="" onClick={(e) => self.handleCheck(e)} type="checkbox" name={id}/>
                  {name}
                </label>
              ))
            })}
          </div>
        ))}
        </div>
      )
    })
  },

  render: function() {
    return (
      <div className="modal-body">
        <div className="modal-close">
          <a href='#' className='cancel-modal' >
            <img src="https://public.cureus.com/close_x.png" />
          </a>
        </div>
        <div className="modal-content">
          <label htmlFor='message_text'>Message text</label>
          <textarea id='message_text'/>
          <p className="description">Your comment will be emailed to all editors assigned to this article. You may notify additional editors or admins by selecting them from the below list.</p>
          {this.renderReceipients()}
          <div className='modal-footer'>
            <a href='#' className='button secondary cancel-modal tiny'>Cancel</a>
            <a className='button primary tiny' onClick={() => this.handleSubmit()} disabled={this.state.disableSubmit}>Submit</a>
          </div>
        </div>
      </div>
    )
  }
})

export default MessageModal;