import React from 'react';

const StepByStepOverview = () => {
  return (
    <React.Fragment>
      <h1>Step-by-Step Overview</h1>
      <p>Upon accepting an invitation to review an article, each reviewer is assigned a Greek letter used by the author for identification purposes (e.g. Reviewer Alpha). This ensures that all Cureus reviewers are completely anonymous. All peer review is conducted within our custom-built online editing tool. Here’s how the process works.</p>
      <ol className='decimals indented-for-medium-up'>
        <li>
          <p>
          Peer reviewers are notified of an invitation to review via both email and website notification.
          </p>
          <ul className='alpha space-top-m'>
            <li>
            Email invitations include the article’s title, author, categories and abstract. Two buttons are available: “Accept review” and “Decline review.” Selecting “Accept review” will take you to review mode. Selecting “Decline review” will notify the article’s submitting author and Cureus editors.
            </li>
            <li>
            Website notifications will be visible via a bell icon next to your profile thumbnail in the top right corner of the screen. Clicking the icon will display a modal with the article title and a button to view the article invitation on the My Reviews tab of your dashboard.
            </li>
          </ul>
        </li>
        <li>
          <p>Once in review mode, highlight text to leave your comments. When you’re finished adding comments to the text, click the “Submit review” button to proceed to the peer review questionnaire before submitting your review.</p>
          <ul className='alpha space-top-m'>
            <li>
              <p>If you are not ready to proceed to the questionnaire, click the “Save and exit” button to return to the My Reviews tab of your dashboard. (Clicking “Save and exit” does not submit the review.)</p>
            </li>
            <li>
              <p>You may re-enter review mode at any time by selecting “Continue my review” in the My Reviews tab of your dashboard or by clicking “Accept review” in the original email invitation (as mentioned in Step 1).</p>
            </li>
          </ul>
        </li>
        <li>
          <p>The peer review questionnaire includes four to six questions depending on article type. See below for a complete listing of all reviewer questions.</p>
        </li>
        <li>
          <p>Your responses will be delivered to the submitting author and/or Cureus editorial staff as indicated below.</p>
        </li>
        <li>
          <p>
          Finally, you’ll then be asked to disclose any potential conflicts of interest before submitting your review.
          </p>
        </li>
      </ol>
      <h3>
        Peer Review Questionnaire
      </h3>
      <p>Your responses to the following questions will be delivered to the submitting author and Cureus editorial staff. Responses are required for all questions with some requiring at least 200 characters. Responses lacking in substantive feedback may result in the rejection of your peer review so please be thoughtful and detailed when providing your responses.
      </p>
      <h4>
        Original Article
      </h4>
      <ol className='decimals indented-for-medium-up'>
        <li>
          <p>What is your assessment of the presentation of the study objectives? For example, are they clearly stated? Are revisions necessary to ensure reader comprehension?
          </p>
        </li>
        <li>
          <p>Are the study methods reported in sufficient detail to allow for their replicability and/or reproducibility? Why or why not?
          </p>
        </li>
        <li>
          <p>Please assess the strengths and limitations of the study/theory/methods/argument and whether they are accurately described by the authors.
          </p>
        </li>
        <li>
          <p>How are the interpretation of results and study conclusions supported by the data and the study design?
          </p>
        </li>
        <li>
          <p>Does the article have flaws which should prohibit its publication?
          </p>
        </li>
      </ol>
      <h4>
        Case Report
      </h4>
      <ol className='decimals indented-for-medium-up'>
        <li>
          <p>What is your assessment of the introduction? For example, does it provide enough details to make the report accessible to readers who are not familiar with the topic?
          </p>
        </li>
        <li>
          <p>Does the article clearly state what the case presentation is and what was observed so that someone can use this description to identify similar symptoms or presentations in another patient? Why or why not?
          </p>
        </li>
        <li>
          <p>Are the figures and tables clearly explained and annotated?
          </p>
        </li>
        <li>
          <p>Please assess the analysis and reporting of data in the text. Please suggest improvements if needed.
          </p>
        </li>
        <li>
          <p>Do the conclusions match the data presented?
          </p>
        </li>
      </ol>
      <h4>
        Review Article
      </h4>
      <ol className='decimals indented-for-medium-up'>
        <li>
          <p>What is your assessment of the introduction? For example, does it provide enough details to make the article accessible to readers who are not familiar with the topic?
          </p>
        </li>
        <li>
          <p>Is all recent and relevant literature included in this review? Are there any references that do not relate to the cited text?
          </p>
        </li>
        <li>
          <p>Please assess the review structure, flow and writing and highlight anything that requires improvement. For example, the addition of subheadings, shortening of text, reorganization of sections, or moving details from one section to another, following PRISMA guidelines.
          </p>
        </li>
        <li>
          <p>Is the review reported in sufficient detail to allow for its replicability and/or reproducibility (e.g., search strategies disclosed, inclusion criteria and risk of bias assessment for individual studies stated, summary methods specified)?
          </p>
        </li>
        <li>
          <p>Is the statistical summary method (e.g., meta-analysis, meta-regressions) and its reporting (e.g., P-values, 95%CIs, etc.) appropriate and well described? If not, please suggest improvements. Please also clearly indicate if the review requires additional peer review by a statistician.
          </p>
        </li>
      </ol>
      <h4>
        Technical Report
      </h4>
      <ol className='decimals indented-for-medium-up'>
        <li>
          <p>What is your assessment of the introduction? For example, does it provide enough details to make the report accessible to readers who are not familiar with the topic?
          </p>
        </li>
        <li>
          <p>How do the authors describe the process, progress, or results of technical or scientific research or the state of a technical or scientific research problem?
          </p>
        </li>
        <li>
          <p>Are the figures and tables clearly explained and annotated?
          </p>
        </li>
        <li>
          <p>Please assess the analysis and reporting of data in the text. Please suggest improvements if needed.
          </p>
        </li>
        <li>
          <p>Do the conclusions match the data presented?
          </p>
        </li>
      </ol>
      <h4>
        Editorial
      </h4>
      <ol className='decimals indented-for-medium-up'>
        <li>
          <p>What is your assessment of the abstract? For example, does it accurately and concisely summarize the main points provided in the article?
          </p>
        </li>
        <li>
          <p>Is this a relevant, timely topic worthy of publication as an editorial? Why or why not?
          </p>
        </li>
        <li>
          <p>Does the editorial have flaws which should prohibit its publication?
          </p>
        </li>
      </ol>
      <h4>
        All Article Types
      </h4>
      <p>Your response to the following question will be delivered only to the Cureus editorial staff.
      </p>
      <ol className='decimals indented-for-medium-up'>
        <li>
        <p>Is the medical science and any associated conclusions contained within this article credible and potentially valuable? (Your response to this question will be sent to the journal editors only.)
        </p>
          <ul className='alpha space-top-m'>
            <li>
              <p>Yes (Accept after revisions)</p>
            </li>
            <li>
              <p>Decision pending (Please send for re-review after revisions</p>
            </li>
            <li>
              <p>No (Do not publish)</p>
            </li>
          </ul>
        </li>
      </ol>    
    </React.Fragment>
  )
}

export default StepByStepOverview;